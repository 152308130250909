import {
	search_result_entry,
	search_result,
	Api,
} from './search.js';

import {
	configure,
	compile,
	Template,
} from 'nunjucks';

const nunjucks = ((window as Window & typeof globalThis & {
	nunjucks: {
		configure: typeof configure,
		compile: typeof compile,
	},
}).nunjucks);

nunjucks.configure({
	autoescape: true,
});

let search_results_template:Template, post_summary_template:Template;

const form = document.forms[0];
const output = document.querySelector('output') as HTMLOutputElement;

let results:search_result = {
	posts: [],
	execution_time: 0,
};

declare type sort_values = 'relevance' | 'old' | 'new';

let was_sort: sort_values = 'relevance';
let sort: sort_values = 'relevance';
let observer:IntersectionObserver;

form.addEventListener('change', async (e) => {
	if ((e.target as HTMLElement).matches('input[name="sort"]')) {
		sort = (e.target as HTMLInputElement).value as sort_values;

		if (sort !== was_sort) {
			results.posts = results.posts.sort(
				sort === 'relevance'
					? sort_relevance
					: ('old' === sort ? sort_old : sort_new)
			);

			was_sort = sort;
			toggle_fieldsets(true);
			await render_results();
			toggle_fieldsets(false);
		}
	}
});

async function do_search(e = null) {
	if (e) {
		e.preventDefault();
	}

	output.textContent = 'Searching...';

	await new Promise((yup) => {
		requestIdleCallback(yup);
	});

	const data = new FormData(form);
	const filters = Api.SearchOptions(data);

	if (filters.date_min) {
		(
			form.querySelector('input[name="dn"]') as HTMLInputElement
		).value = (new Date(filters.date_min)).toISOString().split('T')[0];
	}

	if (filters.date_max) {
		(
			form.querySelector('input[name="dx"]') as HTMLInputElement
		).value = (new Date(filters.date_max)).toISOString().split('T')[0];
	}

	await new Promise((yup) => {
		requestIdleCallback(yup);
	});

	if (e) {
		const params = Api.URLSearchParams(filters);

		history.pushState(
			{},
			'',
			`?${params}`
		);
	}

	await new Promise((yup) => {
		requestIdleCallback(yup);
	});

	results = await Api.search(filters);

	console.info(
		`found ${results.posts.length
		} results in ${results.execution_time / 1000
		} seconds`
	);

	await new Promise((yup) => {
		requestAnimationFrame(yup);
	});

	results.posts = results.posts.sort(
		sort === 'relevance'
			? sort_relevance
			: ('old' === sort ? sort_old : sort_new)
	);

	await render_results();
}

form.addEventListener('submit', do_search);

form.querySelector('div > label[for="versions"] + button').addEventListener('click', (e) => {
	const button = e.target as HTMLButtonElement;
	const li = button.parentNode.parentNode as HTMLLIElement;

	const pressed = 'true' === button.getAttribute('aria-pressed');

	li.classList.toggle('modal');
	button.setAttribute('aria-pressed', pressed ? 'false' : 'true');
});

async function handle_query_string() {
	const params = new URLSearchParams(location.search);

	const c = (params.getAll('c[]') || []);
	const dn = params.get('dn') || '';
	const dx = params.get('dx') || '';
	const i = params.get('i') || '';
	const s = (params.getAll('s[]') || []);
	const v = params.getAll('v[]') || [];
	const comments = '1' === params.get('comments');
	const author = params.get('author');
	const sort_from_query = params.get('sort') || 'relevance';
	const q = params.get('q') || '';

	if (
		sort_from_query === 'relevance'
		|| sort_from_query === 'old'
		|| sort_from_query === 'new'
	) {
		sort = sort_from_query;
		was_sort = sort;
	}

	function option_handler(
		values: string[],
		select: HTMLSelectElement
	): void {
		[...select.querySelectorAll('option')].forEach(
			(option: HTMLOptionElement) => {
				option.selected = values.includes(option.value);
			}
		);
	}

	function radio_handler(
		value: string,
		name: string
	): void {
		[
			...form.querySelectorAll(`input[type="radio"][name="${name}"]`)
		].forEach((input: HTMLInputElement) => {
			input.checked = value === input.value;
		});
	}

	option_handler(c, form.querySelector('select[name="c"]'));
	option_handler(s, form.querySelector('select[name="s"]'));
	option_handler(v, form.querySelector('select[name="v"]'));

	(form.querySelector('input[name="dn"]') as HTMLInputElement).value = dn;
	(form.querySelector('input[name="dx"]') as HTMLInputElement).value = dx;

	(
		form.querySelector('input[name="comments"]') as HTMLInputElement
	).checked = comments;

	radio_handler(sort, 'sort');
	radio_handler(i, 'i');

	(form.querySelector('input[name="q"]') as HTMLInputElement).value = q;
	(
		form.querySelector('input[name="author"]') as HTMLInputElement
	).value = author;

		await do_search();
}

window.onpopstate = handle_query_string;

async function render_results(): Promise<void> {
	const start = performance.now();

	if (observer) {
		observer.disconnect();
	}

	let optional_message = '';

	if (results.posts.length < 10) {
		const data = new FormData(form);
		if (data.get('dn') || data.get('dx')) {
			optional_message = [
				'It looks like you don\'t have ',
				`${results.posts.length < 1 ? 'any' : 'many'}`,
				' results,',
				' consider adjusting the date filters on your query.',
			].join('');
		} else if (
			'' === (data.get('q') || '').toString().trim()
			&& '' === (data.get('author') || '').toString().trim()
		) {
			optional_message = [
				'It looks like you don\'t have ',
				`${results.posts.length < 1 ? 'any' : 'many'}`,
				' results, you may consider entering a search query',
				' or an author filter.',
			].join('');
		}
	}

	if ( ! search_results_template) {
		search_results_template = nunjucks.compile(
			await (await fetch('/nunjucks/search-results.njk')).text()
		);
	}

	output.innerHTML = search_results_template.render({
		results,
		optional_message,
	});

	(
		output.querySelector('output') as HTMLOutputElement
	).textContent = (performance.now() - start).toString();

	const root = document.getElementById('results');

	let rendered = 0;

	observer = new IntersectionObserver(
		async (
			entries:IntersectionObserverEntry[]
		) => {
			if (entries.length && entries[0].isIntersecting) {
				const sub_results = results.posts.slice(rendered, rendered + 10);

				rendered += sub_results.length;

				if (rendered >= results.posts.length) {
					observer.disconnect();
				}

				await new Promise((yup) => {
					requestIdleCallback(yup);
				});

				const fragment = document.createDocumentFragment();

				const ids = sub_results.map(row => row.id);

				sub_results.forEach((result) => {
					const target = document.createElement('li');
					target.dataset.awaitingSummary = result.id;
					target.id = result.id;

					fragment.appendChild(target);
				});

				for await (const summary of Api.summaries(...ids)) {
					const target = fragment.querySelector(
						`[data-awaiting-summary="${summary.id}"]`
					) as HTMLLIElement;

					delete target.dataset.awaitingSummary;

					if ('error' in summary) {
						target.textContent = summary.error;
						target.classList.add('has-error');

						continue;
					}

					target.dataset.answered = summary.answered ? 'true' : 'false';
					target.dataset.status = summary.status;

					if ( ! post_summary_template) {
						post_summary_template = nunjucks.compile(
							await (await fetch('/nunjucks/post-summary.njk')).text()
						);
					}

					target.innerHTML = post_summary_template.render({
						post: Object.assign({}, summary, {
							creation_iso: (new Date(summary.creation_date)).toISOString()
						}),
					});
				}

				requestIdleCallback(() => {
				root.appendChild(fragment);
				});
			}
		},
		{
	});

	observer.observe(output.querySelector('hr'));
}

function sort_relevance(a: search_result_entry, b: search_result_entry): number {
	return b.relevance - a.relevance;
}

function sort_old(a: search_result_entry, b: search_result_entry): number {
	const sort = a.creation_date - b.creation_date;

	return sort ? sort : sort_relevance(a, b);
}

function sort_new(a: search_result_entry, b: search_result_entry): number {
	const sort = b.creation_date - a.creation_date;

	return sort ? sort : sort_relevance(a, b);
}

function toggle_fieldsets(disabled: boolean): void {
	[...form.querySelectorAll('fieldset:disabled')].forEach(
		(fieldset: HTMLFieldSetElement) => {
			fieldset.disabled = disabled;
		}
	);
}

async function load_authors() : Promise<void> {
	const author_usernames = document.createDocumentFragment();

	for (
		const username of (
			await (
				await fetch('/data/authors.json')
			).json()
		) as string[]
	) {
		const option = document.createElement('option');
		option.value = username;

		author_usernames.appendChild(option);
	}

	form.querySelector('datalist[id="author-usernames"]').appendChild(
		author_usernames
	);
}

form.querySelector('#author').addEventListener('focus', load_authors, {
	once: true,
});

toggle_fieldsets(false);

if ([...(new URLSearchParams(location.search)).entries()].length > 0) {
	await handle_query_string();
}
