import {
	summary_result,
} from '../express/routes/post-summary.js';
import {
	status,
	status_as_type,
} from '../satisfactory-qasite-jsapi/src/utils/schema.js';

import {
	search_options,
	search_result_properties,
} from '../api/search.js';

const {
	enum: status_options,
} = status;

export type search_result_entry = search_result_properties & {
	id: string,
};

export type search_result = {
	posts: search_result_entry[],
	execution_time: number,
};

export {
	search_options,
	summary_result,
	status_options,
	status_as_type,
};

export class Api
{
	static URLSearchParams(search_options: search_options) : URLSearchParams
	{
		const {
			term = '',
			date_min = null,
			date_max = null,
			answered,
			author_username = '',
			search_comments,
			statuses = null,
			versions = null,
			categories = null,
		} = search_options;

		const data = new FormData();

		if ('' !== term.trim()) {
			data.set('q', term.trim());
		}

		if (null !== date_min) {
			data.set('dn', date_min.toString());
		}

		if (null !== date_max) {
			data.set('dx', date_max.toString());
		}

		if (null !== answered) {
			data.set('i', answered ? '1' : '0');
		}

		if ('' !== author_username.trim()) {
			data.set('author', author_username.trim());
		}

		if (search_comments) {
			data.set('comments', '1');
		}

		if (statuses && statuses.length) {
			for (const status of statuses.sort()) {
				data.append('s[]', status);
			}
		}

		if (versions && versions.length) {
			for (const version of versions.sort()) {
				data.append('v[]', version);
			}
		}

		if (categories && categories.length) {
			for (const category of categories.sort()) {
				data.append('c[]', category);
			}
		}

		return new URLSearchParams((data as unknown) as Record<string, string>);
	}

	static SearchOptions(data: FormData): search_options
	{
		const comments = '1' === data.get('comments')?.toString();
		const interactions: string = data.get('i')?.toString() || 'all';
		const categories = (data.getAll('c') || []).map(e => e.toString());
		const version_number: string[] = (data.getAll('v') || []).map(e => e.toString());
		const status = (
			data.getAll('s') || []
		).map(e => e.toString()).filter(
			maybe => (status_options as string[]).includes(maybe)
		) as status_as_type[];
		const min_date_iso = (data.get('dn') || '').toString();
		const max_date_iso = (data.get('dx') || '').toString();
		const author_username = (data.get('author') || '').toString().trim();

		let min_date = '' !== min_date_iso ? new Date(min_date_iso) : null;
		let max_date = '' !== max_date_iso
			? new Date(`${max_date_iso}T23:59:59Z`)
			: null;

		if (min_date && max_date && min_date.getTime() > max_date.getTime()) {
			[
				min_date,
				max_date,
			] = [
				max_date,
				min_date,
			];
		}

		if (min_date) {
			min_date.setHours(0, 0, 0);
		}
		if (max_date) {
			max_date.setHours(23, 59, 59, 999);
		}

		const filters: search_options = {
			term: data.get('q').toString(),
			search_comments: comments,
			answered: 'all' === interactions ? null : ('answered' === interactions),
		};

		if (null !== min_date) {
			filters.date_min = min_date.getTime();
		}

		if (null !== max_date) {
			filters.date_max = max_date.getTime();
		}

		if (status.length) {
			filters.statuses = status;
		}

		if (version_number.length) {
			filters.versions = version_number;
		}

		if (categories.length) {
			filters.categories = categories;
		}

		if ('' !== author_username) {
			filters.author_username = author_username;
		}

		return filters;
	}

	static async search(search_options: search_options) : Promise<search_result>
	{
		const started = performance.now();
		const params = Api.URLSearchParams(search_options);

		const json = await (await fetch(`/api/?${params}`, {
			credentials: 'omit',
		})).json() as {[key: string]: search_result_properties};

		return {
			posts: Object.entries(json).map(row => {
				const [id, {
					relevance,
					creation_date,
				}] = row;
				return {
					id,
					relevance,
					creation_date,
				};
			}),
			execution_time: performance.now() - started,
		};
	}

	static #summaries_chunk_size = 4;

	static async* summaries(...ids:string[]) : AsyncGenerator<summary_result|{id: string, error:string}, void, undefined>
	{
		for (let i = 0; i < ids.length; i += Api.#summaries_chunk_size)
		{
			await new Promise((yup) => {
				requestIdleCallback(yup);
			});

			yield* (await Promise.all(ids.slice(i, i + Api.#summaries_chunk_size).map(
				(id) : Promise<summary_result|{id: string, error:string}> => {
					return new Promise((yup, nope) => {
						fetch(`/api/post-summary/${id}`).then(r => {
							r.clone().json().then(json => {
								yup(json);
							}).catch((err) => {
								r.clone().text().then((text) => {
									console.error(err, text);
								});

								yup({id, error: `Failed to decode /api/post-summary/${id}`});
							});
						}).catch((err) => {
							console.error(err);
							yup({id, error: `Failed to fetch /api/post-summary/${id}`});
						});
					});
				}
			)));
		}
	}
}
