export const id = {
	type: 'string',
	pattern: '^[\\da-f]+$',
};

export const creation_date = {
	type: 'integer',
	minimum: 0,
};

export const status: {
	type: 'string',
	enum: [
		'open',
		'in_progress',
		'rejected',
		'solved',
		'fixed_internally',
		'known_issue',
		'closed',
		'hidden',
	],
} = {
	type: 'string',
	enum: [
		'open',
		'in_progress',
		'rejected',
		'solved',
		'fixed_internally',
		'known_issue',
		'closed',
		'hidden',
	],
};

export type status_as_type = (
	'open'
	| 'in_progress'
	| 'rejected'
	| 'solved'
	| 'fixed_internally'
	| 'known_issue'
	| 'closed'
	| 'hidden'
);
